import React from 'react';

const ContentBox = ({ title, children, actions, className = '' }) => (
  <div className={`content-box${className}`}>
    {title && (
      <div className="content-box-title">
        <span>{title}</span>
        {actions && <span className="title-actions">{actions}</span>}
      </div>
    )}
    <div className="content-box-content">{children}</div>
  </div>
);

export default ContentBox;
