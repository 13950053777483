import React from 'react';
import { t } from '@components/core_translations';
const loader =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjkuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9Il94MzhfYWFiZmFmNi1jYzQyLTRjOWMtOWY2MC1lNTU2OTUzZmNkYjgiCgl4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDIyMCAyMjAiCglzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyMjAgMjIwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe29wYWNpdHk6MTtmaWxsOiNBOUFFQjE7ZW5hYmxlLWJhY2tncm91bmQ6bmV3ICAgIDt9Cgkuc3Qxe29wYWNpdHk6MTtmaWxsOiM1NjVjNjU7fQoJLnN0MntmaWxsOm5vbmU7c3Ryb2tlOiNGQkZDRkQ7c3Ryb2tlLXdpZHRoOjE2O3N0cm9rZS1taXRlcmxpbWl0OjEwLjAwMDE7c3Ryb2tlLWRhc2hhcnJheTo5MC4wMDA1LDI3MC4wMDE1O2FuaW1hdGlvbjogc3BpbiAycyBsaW5lYXIgaW5maW5pdGV9CglAa2V5ZnJhbWVzIHNwaW4geyBmcm9te3N0cm9rZS1kYXNob2Zmc2V0OiAzNjA7fSB0b3tzdHJva2UtZGFzaG9mZnNldDowfSB9Cjwvc3R5bGU+CjxjaXJjbGUgY2xhc3M9InN0MCIgY3g9IjEwOS45IiBjeT0iMTExLjUiIHI9Ijk5LjYiLz4KPGNpcmNsZSBjbGFzcz0ic3QxIiBjeD0iMTA5LjkiIGN5PSIxMTEuNSIgcj0iOTkuNiIvPgo8ZWxsaXBzZSBjbGFzcz0ic3QyIiBjeD0iMTA5LjkiIGN5PSIxMTEuNSIgcng9IjU2LjUiIHJ5PSI1Ni41Ii8+Cjwvc3ZnPgo=';

const Loader = ({ button = false }) => (
  <div className={button ? 'vin-btn-soft vin-btn--success loader loader--button' : 'loader loader--display'}>
    <img src={loader} alt="" />
    <span>{t('loadingText')}</span>
  </div>
);

export default Loader;
