import React from 'react';
import Button from './Button';
import Loader from './Loader';

const SubmitButton = props => (
  <React.Fragment>
    {!props.submitting && <Button {...props} type="submit" />}
    {props.submitting && <Loader button />}
  </React.Fragment>
);

export default SubmitButton;
