import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const VinDropdown = ({ id, label, children, buttonClasses, ...props }) => {
  // buttonRef and popupRef are references to the button and popup elements respectively.
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const keydownHandlerRef = useRef();

  // isOpen is a state variable that determines whether the popup is open or not.
  const [isOpen, setIsOpen] = useState(false);
  const [focusableElements, setFocusableElements] = useState([]);

  // buttonClass is a string that contains the CSS classes for the button.
  const buttonClass = buttonClasses ? 'vin-dropdown ' + buttonClasses : 'vin-dropdown';

  // popupClass is a memoized string that contains the CSS classes for the popup.
  const popupClass = useMemo(
    () => (isOpen ? 'vin-dropdown__popup vin-dropdown__popup--show' : 'vin-dropdown__popup'),
    [isOpen]
  );

  // positionPopup is a function that calculates and sets the position of the popup.
  const positionPopup = () => {
    if (!buttonRef.current) {
      return; // Exit the function to avoid further execution
    }

    const { offsetLeft, offsetWidth, offsetTop, offsetHeight } = buttonRef.current;
    let popupLeft = offsetLeft;
    if (window.innerWidth / 2 < offsetLeft + offsetWidth / 2) {
      popupLeft = offsetLeft + offsetWidth - popupRef.current.offsetWidth;
    }
    popupRef.current.style.left = `${popupLeft}px`;
    popupRef.current.style.top = `${offsetTop + offsetHeight}px`;
  };

  // debouncedPositionPopup is a memoized, debounced version of the positionPopup function.
  // It's recalculated only when the component unmounts and remounts.
  const debouncedPositionPopup = useCallback(debounce(positionPopup, 250), []);

  const updateTabIndex = shouldAdd => {
    focusableElements.forEach(elem => {
      if (shouldAdd) {
        elem.removeAttribute('tabIndex');
      } else {
        elem.tabIndex = -1;
      }
    });
  };

  const showPopup = (popup, keyboard) => {
    updateTabIndex(true);
    setIsOpen(true);
    positionPopup();

    const firstElem = focusableElements[0];
    const lastElem = focusableElements[focusableElements.length - 1];

    if (keyboard) {
      setTimeout(() => {
        firstElem.focus();
      }, 20);
    }

    const keydownHandler = e => {
      switch (e.key) {
        case 'Tab':
          if (e.shiftKey && document.activeElement === firstElem) {
            e.preventDefault();
            lastElem.focus();
          } else if (!e.shiftKey && document.activeElement === lastElem) {
            e.preventDefault();
            firstElem.focus();
          }
          break;
        case 'Escape':
          hidePopups();
          break;
        case 'Enter':
        case ' ':
          const focusedElem = document.activeElement;
          if (focusedElem) {
            focusedElem.click();
          }
          break;
        default:
          break;
      }
    };

    keydownHandlerRef.current = keydownHandler;
    popup.addEventListener('keydown', keydownHandlerRef.current);
  };

  const hidePopups = () => {
    setIsOpen(false);
    updateTabIndex(false);
    buttonRef.current.focus();
    if (keydownHandlerRef.current) {
      popupRef.current.removeEventListener('keydown', keydownHandlerRef.current);
      keydownHandlerRef.current = null; // Add this line to clean up the reference
    }
  };

  const handleClick = e => {
    if (isOpen) {
      hidePopups();
    } else {
      showPopup(popupRef.current, e.detail === 0);
    }
  };

  useEffect(() => {
    if (popupRef.current) {
      setFocusableElements(
        popupRef.current.querySelectorAll('a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])')
      );
    } else {
      setFocusableElements([]);
    }
  }, [popupRef.current]);

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = e => {
        if (!(popupRef.current?.contains(e.target) || buttonRef.current?.contains(e.target))) {
          hidePopups();
        }
      };

      document.body.addEventListener('click', handleClickOutside);
      window.addEventListener('resize', debouncedPositionPopup);

      // Cleanup function to remove the event listeners when the component is unmounted or isOpen changes
      return () => {
        document.body.removeEventListener('click', handleClickOutside);
        window.removeEventListener('resize', debouncedPositionPopup);
      };
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <button ref={buttonRef} type="button" className={buttonClass} onClick={handleClick} {...props}>
        {label}
      </button>
      <div ref={popupRef} className={popupClass} id={id}>
        {children}
      </div>
    </React.Fragment>
  );
};
