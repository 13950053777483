import React from 'react';
import { t } from '@components/core_translations';

const LanguageToggle = ({ setLanguage }) => (
  <div className="language-toggle">
    <label>
      <input type="radio" name="languageToggle" defaultChecked onChange={() => setLanguage('en')} />
      <abbr title={t('English')}>EN</abbr>
    </label>
    <label>
      <input type="radio" name="languageToggle" onChange={() => setLanguage('fr')} />
      <abbr title={t('French')}>FR</abbr>
    </label>
  </div>
);

export default LanguageToggle;
