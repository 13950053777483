import React from 'react';

export const sortCurrency = (a, b) =>
  Number(a.replace(/[^0-9.-]+/g, '')) > Number(b.replace(/[^0-9.-]+/g, '')) ? 1 : -1;

export const commas = n => {
  var parts = n.toString().split('.');
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? '.' + parts[1] : '');
};

const money = n => {
  const precision = 2;
  const sign = n < 0 ? '-' : '';

  // Take numbers in any format and round to 2 decimals if needed
  const amount = Number(Math.round(parseFloat(parseFloat(Math.abs(n || 0)) + 'e' + precision)) + 'e-' + precision);
  return sign + '$' + amount.toLocaleString('en-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export default money;

export const TableMoney = ({ children }) => (
  <span className={children < 0 ? 'table__money table__money--red' : 'table__money'}>{money(children)}</span>
);
