import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export const VinBtn = ({
  children,
  small,
  pop,
  icon,
  kind = '',
  href,
  onClick,
  variant = '',
  className,
  to,
  count,
  active,
  input,
  value,
  ...props
}) => {
  const classes = classnames({
    'vin-btn': !kind, // Outline appearance
    'vin-btn-loud': kind === 'loud', // Solid color background
    'vin-btn-soft': kind === 'soft', // Muted color background
    'vin-btn-link': kind === 'link', // Text Link appearance
    'vin-btn--default': variant === 'default', // Only used for Link buttons
    'vin-btn--primary': variant === 'primary', // Not used for Link buttons
    'vin-btn--success': variant === 'success', // Green
    'vin-btn--danger': variant === 'danger', // Red
    'vin-btn--sm': small, // Make padding smaller
    'vin-btn--pop': pop, // Make button pop out with shadow
    'vin-btn--icon': icon, // Make circle button for icon
    'vin-btn--active': active, // Active state
    [className]: className,
  });

  const inner =
    icon && count > 0 ? (
      <React.Fragment>
        <span className="vin-btn__count">{count}</span>
        {children}
      </React.Fragment>
    ) : (
      children
    );

  if (to) {
    return (
      <Link to={to} className={classes} {...props}>
        {inner}
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} onClick={onClick} className={classes} {...props}>
        {inner}
      </a>
    );
  }

  if (input === 'submit') {
    return <input type="submit" className={classes} value={value} {...props} />;
  }

  if (input === 'button') {
    return <input type="button" className={classes} value={value} {...props} />;
  }

  return (
    <button onClick={onClick} className={classes} type="button" {...props}>
      {inner}
    </button>
  );
};

export const VinBtnTester = () => {
  const kinds = ['', 'soft', 'loud', 'link'];
  const variants = ['', 'primary', 'success', 'danger'];
  const sizes = ['', 'small', 'icon'];
  const pops = ['', 'pop'];

  const buttons = [];

  kinds.forEach(k => {
    const row = sizes.map(s => {
      return pops.map(p => {
        return variants.map(v => {
          const key = `${v}_${k}_${s}_${p}`;
          return (
            <React.Fragment>
              <VinBtn
                key={key}
                kind={k}
                variant={v}
                small={s === 'small'}
                pop={p === 'pop'}
                icon={s === 'icon'}
                active={false}
              >
                <i className="fa fa-star"></i>
                <span className={s === 'icon' && 'sr-only'}> Text Button</span>
              </VinBtn>
              <VinBtn
                key={key}
                kind={k}
                variant={v}
                small={s === 'small'}
                pop={p === 'pop'}
                icon={s === 'icon'}
                active={true}
              >
                <i className="fa fa-star"></i>
                <span className={s === 'icon' && 'sr-only'}> Text Button</span>
              </VinBtn>
            </React.Fragment>
          );
        });
      });
    });

    buttons.push(<div className="vin-btn-tester__row">{row}</div>);
  });

  return <div className="vin-btn-tester">{buttons}</div>;
};
