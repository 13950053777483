/* eslint no-console:0 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactRailsUJS from 'react_ujs';
import React from 'react';

import LazyLoader from '../components/LazyLoader';

ReactRailsUJS.useContexts([
  require.context('components/header', true), // Force header in main bundle
  ref => {
    if (ref === './Header') {
      return;
    }

    const componentName = ref.substring(2); // Skip ./ @ start
    return componentParams => {
      return React.createElement(LazyLoader, { componentName, componentParams }, null);
    };
  },
]);
