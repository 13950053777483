module.exports = {
  View: 'Voir',
  Pay: 'Payer',
  '# of Seats': '# de Sièges',
  '# Seats': '# de Places',
  'Account Balance': 'Solde du Compte',
  'Account Details': 'Détails du Compte',
  'Account Manager': 'Gestionnaire de Compte',
  'Account State': 'Etat du Compte',
  'Account Status': 'Statut du Compte',
  'accounts.trial_expired': 'Essai gratuit expiré',
  active: 'Actif',
  'Address Line 2': 'Adresse Ligne 2',
  'Amount Paid': 'Montant Payé',
  'Are you sure you want to unassign this seat?': "Êtes-vous sûr de vouloir annuler l'attribution de ce siège?",
  'Assigned At': 'Attribué à',
  'Balance Due': 'Solde Dû',
  'Billing Email': 'Email de Facturation',
  'billing history': 'historique de facturation',
  'Billing State': 'État de Facturation',
  'Billing Status': 'Statut de Facturation',
  Canceled: 'Annulé',
  credit: 'Crédit',
  'Card Type': 'Type de carte',
  'character_limit_field.limit_error': 'est trop long (maximum {{characterLimit}} caractères)',
  'Click to update': 'Mettre à jour',
  'Company Name': 'Nom de la Compagnie',
  'Company/Branch Name': 'Nom de la Compagnie/Branch',
  'complete_page.header': 'La commande #{{id}} est maintenant complète, merci!',
  'complete_page.blurb':
    "Vous recevrez un email de confirmation bientôt. Si vous avez des questions, veuillez contacter notre équipe d'assistance en utilisant le bouton d'aide vert en haut de la page.",
  'complete_page.links':
    'Pour attribuer ces cours aux étudiants de votre compte, veuillez visiter <a href="{{coursesPath}}">la page des cours</a>. Pour voir la facture d’achat de cette commande, veuillez visiter <a href="{{billingPagePath}}">la page de facturation</a>.',
  Content: 'Contenu',
  'Cost Per Period': 'Coût par Période',
  'Current Cycle': 'Cycle en Cours',
  'Current Plan': 'Plan Actuel',
  'edit_po_number_modal.edit_invoice_po_num': 'Modifier PO # de la facture',
  'Edit Account Details': 'Modifier les Détails du Compte',
  'End Date': 'Fin Date',
  'Expired on': '(A expiré le {{trialExpiryAt}})',
  'Expires on': '(Expire le {{trialExpiryAt}})',
  Expiring: 'Expire {{date}}',
  'feedback.button': 'Commentaires',
  'feedback.cancel': 'Annuler',
  'feedback.comments': 'Commentaires',
  'feedback.description':
    'Vous avez peut-être remarqué que nous avons récemment mis à jour notre système et si vous avez des commentaires, nous aimerions les entendre! Vous pouvez remplir ce formulaire aussi souvent que vous le souhaitez au cours des prochaines semaines. Vos commentaires nous aideront à améliorer encore plus le système!',
  'feedback.help': "S'il vous plaît visitez notre Aide pour obtenir de l'aide de notre équipe de soutien.",
  'feedback.rating_description':
    'Veuillez évaluer les changements globaux de 1 à 10 étoiles, 1 étant le pire et 10 le meilleur.',
  'feedback.rating': 'Note globale',
  'feedback.submit': 'Soumettre des commentaires',
  'feedback.tip1': "Conseil: cherchez de l'aide?",
  'feedback.tip2': 'On ne répond pas aux commentaires donnés ici.',
  'feedback.title': 'Formulaire de commentaires',
  'First Name': 'Prénom',
  'Full Name': 'Nom Complet',
  'home.add_user_desc': 'Ajoutez un seul utilisateur à votre compte.',
  'home.add_user': 'Ajouter un utilisateur',
  'home.assign_training_desc': 'Attribuer des cours à vos stagiaires.',
  'home.assign_training': 'Assigner une formation',
  'home.buy_courses_desc': 'Parcourez notre catalogue de cours.',
  'home.buy_courses': 'Acheter des cours',
  'home.course_builder_desc': 'Créer et éditer des cours en ligne.',
  'home.course_builder': 'Constructeur de Cours',
  'home.custom_fields_desc': 'Ajouter des champs personnalisés aux enregistrements.',
  'home.custom_fields': 'Les champs personnalisés',
  'home.edit': 'Modifier',
  'home.editor': 'Éditeur',
  'home.import_users_desc': 'Importer plusieurs utilisateurs via une feuille de calcul.',
  'home.import_users': 'Importer des utilisateurs',
  'home.quick_links': 'Liens rapides',
  'home.save': 'Sauvegarder',
  'home.training_rules_desc': 'Affectation de déclencheur via des règles de formation.',
  'home.training_rules': 'Règles de formation',
  'home.welcome_to_your_lms': 'Bienvenue dans votre LMS',
  'In Good Standing': 'En règle',
  'in use': 'utilisé',
  invoice_cancelled_payment_no_longer_required: "Cette facture a été annulée et le paiement n'est plus nécessaire.",
  'Invoice #': 'Facture #',
  'Invoice Date': 'Date de Facturation',
  'Invoice with CAD': 'Facture: # {{invoice_id}}: {{total}} CAD',
  'Last 4 Digits': '4 Derniers Chiffres',
  'Last Name': 'Nom de Famille',
  'Manual (PO/Cheque/EFT/Other)': 'Manuel (PO/Cheque/EFT/Autre)',
  'Membership Signed By': 'Adhésion signée par',
  'Membership State': "État d'Adhésion",
  'Membership Status': "Statut d'Adhésion",
  'Membership Type': "Type d'Adhésion",
  'N/A': 'N/A',
  'Name on Card': 'Nom sur Carte',
  'Never Subscribed': 'Jamais Abonné',
  'No Results': 'Aucun Résultat',
  'Not valid.': 'Pas Valide.',
  'Overall Status': 'Situation Globale',
  Paused: 'En pause',
  'Pay Now': 'Payez',
  'Payment Method': 'Mode de Paiement',
  'Payment Type': 'Type de Paiement',
  'Payments Received': 'Paiement Reçus',
  'Postal Code': 'Code Postal',
  'Postal/Zip': 'Code postal',
  'PO #': 'PO #',
  'Price Per Seat': 'Prix ​​Par Siège',
  'Primary Admin': 'Administrateur Principal',
  'products.description_to_be_added':
    "La description du parcours long sera ajoutée sous peu. S'il vous plaît revenez bientôt.",
  'Province/State': 'Province/Etat',
  'Sale Type': 'Vente Type',
  'sales_report_page.date_range': 'Date Intervalle',
  'sales_report_page.perspective': 'Perspective',
  'sales_report_page.quick_filter': 'Filtre rapide',
  'sales_report_page.sales_report': 'Rapport des ventes',
  'Search...': 'Rechercher...',
  'Advanced Search': 'Recherche Avancée',
  'Start Date': 'Début Date',
  'Starts on': '(Commence à {{trialStartAt}})',
  'Sub Total': 'Total Partiel',
  'Table of Contents': 'Table des Matières',
  'Tax Total': 'Total de Taxes',
  'Time Period': 'Période de Temps',
  "This account doesn't have any sub-branches.": "Ce compte n'a pas de sous-branches.",
  'Update Billing Details': 'Mettre à jour les détails de facturation',
  'Use New Payment Method': 'Utiliser un nouveau mode de paiement',
  'Use Saved Payment Details': 'Utiliser les détails de paiement sauvegardés ({{cardType}} ****-****-****-{{last4}})',
  'Update Billing Email': "Mettre à jour l'email de facturation",
  'View Invoice': 'Voir la Facture',
  Active: 'Actif',
  Address: 'Adresse',
  Amount: 'Montant',
  automatic: 'automatique',
  Billing: 'Facturation',
  CAD: 'CAD',
  canceled: 'Annulé',
  City: 'Ville',
  Commitment: "Date de fin de l'engagement",
  Country: 'Pays',
  CVC: 'CVC',
  Date: 'Date',
  Delinquent: 'Délinquant',
  Description: 'Description',
  Email: 'Email',
  Expiration: 'Expiration',
  graphqlError: '[GraphQL Erreur]: Message: {{message}}, Location: {{locations}}, Chemin: {{path}}',
  ID: 'ID',
  invoice: 'facture',
  loadingText: 'Chargement...',
  Manager: 'Directeur',
  Name: 'Prénom',
  networkError: '[Network Erreur]: {{networkError}}',
  nextText: 'Suivant',
  noDataText: 'Aucun résultat',
  notAbleToSave:
    "Nous sommes désolés, mais nous n'avons pas pu enregistrer vos détails d'adresse. N'hésitez pas à réessayer ou contactez le support technique au 1-877-652-5262 si le problème persiste.",
  ofText: 'de',
  Overdue: 'En Retard',
  pageJumpText: 'aller à la page',
  pageText: 'Page',
  paid: 'payé',
  Pays: 'Pays',
  Phone: 'Téléphone',
  previousText: 'Précédent',
  Product: 'Produit',
  Province: 'Province',
  Purchaser: 'Acheteur',
  Quantity: 'Quantité',
  rowsSelectorText: 'rangées par page',
  rowsText: 'rangées',
  refunded: 'remboursé',
  Refund: 'remboursé',
  seatCannotBeUnassigned:
    'Ce siège ne peut pas être attribué, parce que {{cause}} a terminé sa formation au cours des 3 derniers mois.',
  Standard: 'La norme',
  Status: 'Statut',
  subscription: 'abonnement',
  Suspended: 'Suspendu {{canceledAt}}',
  Tax: 'Impôt',
  total: 'total ',
  Total: 'Total',
  Unassign: 'Désassigner',
  Unassigned: 'Non attribué',
  units: 'des unités',
  unpaid: 'non payé',
  Yr: 'Année',
  privacy: 'Intimité',
  terms: 'Conditions',
  English: 'Anglaise',
  French: 'Français',
};
