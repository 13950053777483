import React from 'react';
import { DateRangePicker } from 'react-dates';
import { t } from '@components/core_translations';

class DateRangeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (this.props.filter) {
      this.state.startDate = this.props.filter.value.startDate;
      this.state.endDate = this.props.filter.value.endDate;
    }
  }
  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const { startKey, endKey, onChange } = this.props;
    return (
      <DateRangePicker
        small
        noBorder
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={t('Start Date')}
        endDatePlaceholderText={t('End Date')}
        displayFormat="MMM DD, YYYY"
        customArrowIcon=" "
        startDateId={startKey}
        endDateId={endKey}
        startDate={startDate}
        endDate={endDate}
        numberOfMonths={1}
        isOutsideRange={() => false}
        focusedInput={focusedInput}
        onDatesChange={newDates => {
          this.setState({ startDate: newDates.startDate, endDate: newDates.endDate });
          onChange({ startDate: newDates.startDate, endDate: newDates.endDate });
        }}
        onFocusChange={newFocus => this.setState({ focusedInput: newFocus })}
      />
    );
  }
}

export default DateRangeFilter;
