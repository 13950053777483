module.exports = {
  '# of Seats': '# of Seats',
  '# Seats': '# Seats',
  'Account Balance': 'Account Balance',
  'Account Manager': 'Account Manager',
  'Account State': 'Account State',
  'Account Status': 'Account Status',
  'accounts.trial_expired': 'Free Trial Expired',
  'Address Line 2': 'Address Line 2',
  'Amount Paid': 'Amount Paid',
  'Are you sure you want to unassign this seat?': 'Are you sure you want to unassign this seat?',
  'Balance Due': 'Balance Due',
  'billing history': 'billing history',
  'Billing State': 'Billing State',
  'Billing Status': 'Billing Status',
  canceled: 'Cancelled',
  'Card Type': 'Card Type',
  'character_limit_field.limit_error': 'is too long (maximum {{characterLimit}} characters)',
  'Click to update': 'Click to update',
  'Company Name': 'Company Name',
  'complete_page.header': 'Order #{{id}} is now complete, thank you!',
  'complete_page.blurb':
    "You'll receive an email confirmation shortly. If you have any questions, please contact our support team using the green help button at the top of the page.",
  'complete_page.links':
    'To assign these courses to trainees on your account, please visit the <a href="{{coursesPath}}">courses page</a>. To view the invoice for this order, please visit the <a href="{{billingPagePath}}">billing page</a>.',
  Content: 'Content',
  'Cost Per Period': 'Cost Per Period',
  'Current Cycle': 'Current Cycle',
  'Current Plan': 'Current Plan',
  'edit_po_number_modal.edit_invoice_po_num': 'Edit Invoice PO #',
  'End Date': 'End Date',
  'Expired on': '(Expired on {{trialExpiryAt}})',
  'Expires on': '(Expires on {{trialExpiryAt}})',
  Expiring: 'Expiring {{date}}',
  'feedback.button': 'Feedback',
  'feedback.cancel': 'Cancel',
  'feedback.comments': 'Comments',
  'feedback.description':
    "You may have noticed we've recently updated our system, and if you have feedback we'd love to hear it! You're welcome to fill out this form as often as you want over the coming weeks. Your feedback will help us improve the system even further!",
  'feedback.help': 'Help',
  'feedback.rating_description':
    'Please rate the overall changes from 1-10 stars, 1 being the worst and 10 being the best.',
  'feedback.rating': 'Overall Rating',
  'feedback.submit': 'Submit Feedback',
  'feedback.tip1': 'Tip: Looking for support? Please visit our',
  'feedback.tip2': 'page to get assistance from our support team. Feedback given here will not be replied to.',
  'feedback.title': 'Feedback Form',
  'First Name': 'First Name',
  'Full Name': 'Full Name',
  'home.add_user_desc': 'Add a single user to your account.',
  'home.add_user': 'Add User',
  'home.assign_training_desc': 'Assign Courses to your trainees.',
  'home.assign_training': 'Assign Training',
  'home.buy_courses_desc': 'Browse our Course Catalogue.',
  'home.buy_courses': 'Buy Courses',
  'home.course_builder_desc': 'Create and edit courses online.',
  'home.course_builder': 'Course Builder',
  'home.custom_fields_desc': 'Add Custom Fields to user records.',
  'home.custom_fields': 'Custom Fields',
  'home.edit': 'Edit',
  'home.editor': 'Editor',
  'home.import_users_desc': 'Import multiple users via spreadsheet.',
  'home.import_users': 'Import Users',
  'home.quick_links': 'Quick Links',
  'home.save': 'Save',
  'home.training_rules_desc': 'Trigger assignment via training rules.',
  'home.training_rules': 'Training Rules',
  'home.welcome_to_your_lms': 'Welcome to your LMS',
  'In Good Standing': 'In Good Standing',
  'in use': 'in use ',
  invoice_cancelled_payment_no_longer_required: 'This invoice has been cancelled, and payment is no longer required.',
  'Invoice #': 'Invoice #',
  'Invoice Date': 'Invoice Date',
  'Invoice with CAD': 'Invoice: #{{invoice_id}}: {{total}} CAD',
  'Last 4 Digits': 'Last 4 Digits',
  'Last Name': 'Last Name',
  'Manual (PO/Cheque/EFT/Other)': 'Manual (PO/Cheque/EFT/Other)',
  'Membership Signed By': 'Membership Signed By',
  'Membership State': 'Membership State',
  'Membership Status': 'Membership Status',
  'Membership Type': 'Membership Type',
  'N/A': 'N/A',
  'Name on Card': 'Name on Card',
  'Never Subscribed': 'Never Subscribed',
  'No Results': 'No Results',
  'Not valid.': 'Not valid.',
  'Overall Status': 'Overall Status',
  Paused: 'Paused',
  'Pay Now': 'Pay Now',
  'Payment Method': 'Payment Method',
  'Payment Type': 'Payment Type',
  'Payments Received': 'Payments Received',
  'Postal Code': 'Postal Code',
  'Postal/Zip': 'Postal/Zip',
  'PO #': 'PO #',
  'Price Per Seat': 'Price Per Seat',
  'Primary Admin': 'Primary Admin',
  'products.description_to_be_added': 'Long course description will be added shortly. Please check back soon.',
  'Province/State': 'Province/State',
  'Sale Type': 'Sale Type',
  'sales_report_page.date_range': 'Date Range',
  'sales_report_page.perspective': 'Perspective',
  'sales_report_page.quick_filter': 'Quick Filter',
  'sales_report_page.sales_report': 'Sales Report',
  'Search...': 'Search...',
  'Advanced Search': 'Advanced Search',
  'Start Date': 'Start Date',
  'Starts on': '(Starts on {{trialStartAt}})',
  'Sub Total': 'Sub Total',
  'Table of Contents': 'Table of Contents',
  'Tax Total': 'Tax Total',
  'Time Period': 'Time Period',
  "This account doesn't have any sub-branches.": "This account doesn't have any sub-branches.",
  'trial_modal.title': 'Create a new trial period',
  'trial_modal.submit': 'Create',
  'trial_modal.immediate': 'Start the Trial immediately',
  'trial_modal.delayed': 'Let me choose when the Trial starts:',
  'Update Billing Details': 'Update Billing Details',
  'Use New Payment Method': 'Use New Payment Method',
  'Use Saved Payment Details': 'Use Saved Payment Details ({{cardType}} ****-****-****-{{last4}})',
  'View Invoice': 'View Invoice',
  Active: 'Active',
  Address: 'Address',
  Amount: 'Amount',
  automatic: 'automatic',
  Billing: 'Billing',
  CAD: 'CAD',
  Canceled: 'Cancelled',
  City: 'City',
  Commitment: 'Commitment End Date',
  Country: 'Country',
  CVC: 'CVC',
  Date: 'Date',
  Delinquent: 'Delinquent',
  Description: 'Description',
  Email: 'Email',
  Expiration: 'Expiration',
  graphqlError: '[GraphQL error]: Message: {{message}}, Location: {{locations}}, Path: {{path}}',
  ID: 'ID',
  invoice: 'invoice',
  loadingText: 'Loading...',
  Manager: 'Manager',
  Name: 'Name',
  networkError: '[Network error]: {{networkError}}',
  nextText: 'Next',
  noDataText: 'No Results',
  notAbleToSave:
    "We're sorry, but we were not able to save your address details. Feel free to try again, or contact support at 1-877-652-5262 if the issue persists.",
  ofText: 'of',
  Overdue: 'Overdue',
  pageJumpText: 'jump to page',
  pageText: 'Page',
  paid: 'paid',
  Pays: 'Pays',
  Phone: 'Phone',
  previousText: 'Previous',
  Product: 'Product',
  Province: 'Province',
  Purchaser: 'Purchaser',
  Quantity: 'Quantity',
  rowsSelectorText: 'rows per page',
  rowsText: 'rows',
  seatCannotBeUnassigned:
    'This seat cannot be unassigned, because {{cause}} has completed training within the last 3 months.',
  Standard: 'Standard',
  Status: 'Status',
  subscription: 'subscription',
  Suspended: 'Suspended {{canceledAt}}',
  Tax: 'Tax',
  total: 'total ',
  Total: 'Total',
  Unassign: 'Unassign',
  Unassigned: 'Unassigned',
  units: 'units',
  unpaid: 'unpaid',
  Yr: 'Yr',
  privacy: 'Privacy',
  terms: 'Terms',
  English: 'English',
  French: 'French',
};
