import i18n from 'i18next';
import us from './public/locales/en/translation';
import fr from './public/locales/fr/translation';

const resources = {
  en: {
    translation: us,
  },
  fr: {
    translation: fr,
  },
};

const lng = window.i18nLocale || 'en';
i18n.init({
  resources,
  lng,
  fallbackLng: 'en',

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export const t = i18n.t.bind(i18n);
export default i18n;
