import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, ModalBody, ModalFooter, Button } from '@components/core_ui_elements';
import { t } from '@components/core_translations';
import { times } from 'lodash';
import { submitFeedbackMutation } from './graphql/SubmitFeedback.graphql';
import { VinBtn } from '@components/vin';

const Star = ({ index, highlighted, select, hover }) => (
  <i
    className={`fa fa-star${highlighted ? ' highlight' : '-o'}`}
    onMouseEnter={() => hover(index)}
    onClick={() => select(index)}
  />
);

const StarList = ({ starCount, selectedIndex, hoverIndex, hover, select, leave }) => (
  <div className="star-list" onMouseLeave={leave}>
    {times(starCount, i => (
      <Star
        key={`star-${i}`}
        index={i}
        hover={hover}
        select={select}
        highlighted={selectedIndex > i || hoverIndex > i}
      />
    ))}
  </div>
);

export const FeedbackButton = ({ ...props }) => (
  <Button id="feedback-link" text {...props}>
    <span>{t('feedback.button')}</span>
  </Button>
);

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      hoverIndex: 0,
      comments: '',
    };
    this.reset = this.reset.bind(this);
  }

  reset() {
    this.setState({ selectedIndex: 0, hoverIndex: 0, comments: '' });
  }

  render() {
    const { closeModal, isOpen } = this.props;
    const { selectedIndex, hoverIndex, comments } = this.state;

    return (
      <Modal title={t('feedback.title')} isOpen={isOpen} closeModal={closeModal}>
        <Mutation
          mutation={submitFeedbackMutation}
          variables={{ rating: selectedIndex, comments }}
          onCompleted={() => {
            closeModal();
            this.reset();
          }}
        >
          {submitFeedback => (
            <React.Fragment>
              <ModalBody extend>
                <div className="feedback-form">
                  <p>{t('feedback.description')}</p>
                  <h3>{t('feedback.rating')}</h3>
                  <p>{t('feedback.rating_description')}</p>
                  <StarList
                    selectedIndex={selectedIndex}
                    hoverIndex={hoverIndex}
                    hover={j => this.setState({ hoverIndex: j + 1 })}
                    select={j => this.setState({ selectedIndex: j + 1 })}
                    leave={() => this.setState({ hoverIndex: 0 })}
                    starCount={10}
                  />
                  <h3>{t('feedback.comments')}</h3>
                  <textarea
                    name="comments"
                    value={comments}
                    onChange={e => this.setState({ comments: e.target.value })}
                  />
                  <p className="tip">
                    {t('feedback.tip1')}
                    &nbsp;<a href={window.reactConfig.helpUrl}>{t('feedback.help')}</a>&nbsp;
                    {t('feedback.tip2')}
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <VinBtn kind="link" onClick={closeModal} style={{ marginRight: '1em' }}>
                  {t('feedback.cancel')}
                </VinBtn>
                <VinBtn kind="loud" pop variant="success" onClick={submitFeedback} disabled={selectedIndex === 0}>
                  {t('feedback.submit')}
                </VinBtn>
              </ModalFooter>
            </React.Fragment>
          )}
        </Mutation>
      </Modal>
    );
  }
}

export default FeedbackForm;
