import React, { useEffect } from 'react';
import fetch from 'unfetch';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';

import { AbilityContext, abilityFor } from '@components/core_permissions';
import { t } from '@components/core_translations';
import CurrentLoginContext from './providers/CurrentLoginContext';

const mainLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => console.log(t('graphqlError', { message, locations, path })));
    }
    if (networkError) console.log(t('networkError', { networkError }));
  }),
  new HttpLink({
    uri: window.reactConfig.graphqlUrl,
    credentials: 'same-origin',
    fetch,
  }),
]);

const uploadLink = createUploadLink({
  uri: window.reactConfig.graphqlUrl,
  credentials: 'same-origin',
  fetch,
});

const httpLink = ApolloLink.split(operation => operation.getContext().hasUpload, uploadLink, mainLink);

// Configuration without boost see here:
// (https://www.apollographql.com/docs/react/advanced/boost-migration)
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

const RouterScroll = withRouter(({ children, history }) => {
  useEffect(() => {
    return history.listen((_, action) => {
      if (action == 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  }, []);

  return <>{children}</>;
});

const CoreBootstrap = ({ basename = '/', currentLogin, currentDistributor, currentSubscription, children }) => {
  return (
    <ApolloProvider client={client}>
      <AbilityContext.Provider value={abilityFor(currentLogin, currentDistributor, currentSubscription)}>
        <CurrentLoginContext.Provider
          value={{
            currentLogin,
            currentDistributor,
            ability: abilityFor(currentLogin, currentDistributor, currentSubscription),
          }}
        >
          <Router basename={basename}>
            <RouterScroll children={children} />
          </Router>
        </CurrentLoginContext.Provider>
      </AbilityContext.Provider>
    </ApolloProvider>
  );
};

export default CoreBootstrap;
