import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('body');

export const ModalFooter = ({ children }) => <div className="modal-footer">{children}</div>;

export const ModalBody = ({ children, extend = false, noOverflow = false }) => (
  <div className={`modal-body${extend ? ' extend' : ''}${noOverflow ? ' no-overflow' : ''}`}>{children}</div>
);

export const Modal = ({ title, children, isOpen, closeModal }) => (
  <ReactModal isOpen={isOpen} className="modal new-modal" overlayClassName="base-modal-wrapper">
    <div className="modal-header">
      <h3 className="modal-title">{title}</h3>
      <button className="close" onClick={() => closeModal()}>
        <i className="fa fa-times" />
        <span className="sr-only">Close</span>
      </button>
    </div>
    <div className="modal-content">{children}</div>
  </ReactModal>
);

export default Modal;
