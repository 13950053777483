export const taxRates = {
  Ontario: 0.13,
  Quebec: 0.05,
  'Nova Scotia': 0.15,
  'New Brunswick': 0.15,
  Manitoba: 0.05,
  'British Columbia': 0.05,
  'Prince Edward Island': 0.15,
  Saskatchewan: 0.05,
  Alberta: 0.05,
  'Newfoundland and Labrador': 0.15,
  'Northwest Territories': 0.05,
  Nunavut: 0.05,
  Yukon: 0.05,
};

export const countryOptions = [
  { label: 'United States', value: 'United States' },
  { label: 'Canada', value: 'Canada' },
];

export const provinceOptions = [
  { label: 'Ontario', value: 'Ontario' },
  { label: 'Quebec', value: 'Quebec' },
  { label: 'Nova Scotia', value: 'Nova Scotia' },
  { label: 'New Brunswick', value: 'New Brunswick' },
  { label: 'Manitoba', value: 'Manitoba' },
  { label: 'British Columbia', value: 'British Columbia' },
  { label: 'Prince Edward Island', value: 'Prince Edward Island' },
  { label: 'Saskatchewan', value: 'Saskatchewan' },
  { label: 'Alberta', value: 'Alberta' },
  { label: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador' },
  { label: 'Northwest Territories', value: 'Northwest Territories' },
  { label: 'Nunavut', value: 'Nunavut' },
  { label: 'Yukon', value: 'Yukon' },
];

export const provinceOptionsForCountry = country => {
  if (country === 'United States') {
    return [
      { label: 'Alabama', value: 'Alabama' },
      { label: 'Alaska', value: 'Alaska' },
      { label: 'Arizona', value: 'Arizona' },
      { label: 'Arkansas', value: 'Arkansas' },
      { label: 'California', value: 'California' },
      { label: 'Colorado', value: 'Colorado' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Delaware', value: 'Delaware' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Georgia', value: 'Georgia' },
      { label: 'Hawaii', value: 'Hawaii' },
      { label: 'Idaho', value: 'Idaho' },
      { label: 'Illinois', value: 'Illinois' },
      { label: 'Indiana', value: 'Indiana' },
      { label: 'Iowa', value: 'Iowa' },
      { label: 'Kansas', value: 'Kansas' },
      { label: 'Kentucky', value: 'Kentucky' },
      { label: 'Louisiana', value: 'Louisiana' },
      { label: 'Maine', value: 'Maine' },
      { label: 'Maryland', value: 'Maryland' },
      { label: 'Massachusetts', value: 'Massachusetts' },
      { label: 'Michigan', value: 'Michigan' },
      { label: 'Minnesota', value: 'Minnesota' },
      { label: 'Mississippi', value: 'Mississippi' },
      { label: 'Missouri', value: 'Missouri' },
      { label: 'Montana', value: 'Montana' },
      { label: 'Nebraska', value: 'Nebraska' },
      { label: 'Nevada', value: 'Nevada' },
      { label: 'New Hampshire', value: 'New Hampshire' },
      { label: 'New Jersey', value: 'New Jersey' },
      { label: 'New Mexico', value: 'New Mexico' },
      { label: 'New York', value: 'New York' },
      { label: 'North Carolina', value: 'North Carolina' },
      { label: 'North Dakota', value: 'North Dakota' },
      { label: 'Ohio', value: 'Ohio' },
      { label: 'Oklahoma', value: 'Oklahoma' },
      { label: 'Oregon', value: 'Oregon' },
      { label: 'Pennsylvania', value: 'Pennsylvania' },
      { label: 'Rhode Island', value: 'Rhode Island' },
      { label: 'South Carolina', value: 'South Carolina' },
      { label: 'South Dakota', value: 'South Dakota' },
      { label: 'Tennessee', value: 'Tennessee' },
      { label: 'Texas', value: 'Texas' },
      { label: 'Utah', value: 'Utah' },
      { label: 'Vermont', value: 'Vermont' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'Washington', value: 'Washington' },
      { label: 'West Virginia', value: 'West Virginia' },
      { label: 'Wisconsin', value: 'Wisconsin' },
      { label: 'Wyoming', value: 'Wyoming' },
    ];
  }

  if (country === 'Canada') {
    return provinceOptions;
  }

  return [];
};

export default [
  'Ontario',
  'Quebec',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'British Columbia',
  'Prince Edward Island',
  'Saskatchewan',
  'Alberta',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nunavut',
  'Yukon',
];
