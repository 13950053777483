import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export const VinBadge = ({ children, kind = '', href, onClick, variant = '', className, to, ...props }) => {
  const classes = classnames({
    'vin-badge': !kind, // Soft appearance
    'vin-badge-loud': kind === 'loud', // Solid color background
    'vin-badge--primary': variant === 'primary', // Blue
    'vin-badge--success': variant === 'success', // Green
    'vin-badge--danger': variant === 'danger', // Red
    'vin-badge--warning': variant === 'warning', // Yellow
    'vin-badge--secondary': variant === 'secondary', // Purple
    'vin-badge--tertiary': variant === 'tertiary', // Orange
    [className]: className,
  });

  const inner = children;

  if (to) {
    return (
      <Link to={to} className={classes} {...props}>
        {inner}
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} onClick={onClick} className={classes} {...props}>
        {inner}
      </a>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={classes} type="button" {...props}>
        {inner}
      </button>
    );
  }

  return (
    <span className={classes} {...props}>
      {inner}
    </span>
  );
};

export const VinBadgeTester = () => {
  const kinds = ['', 'loud'];
  const variants = ['', 'primary', 'success', 'danger', 'warning', 'secondary', 'tertiary'];

  const badges = [];

  kinds.forEach(k => {
    const row = variants.map(v => {
      const key = `${v}_${k}`;
      return (
        <VinBadge key={key} kind={k} variant={v} href="#">
          Text Badge
        </VinBadge>
      );
    });

    badges.push(<div className="vin-badge-tester__row">{row}</div>);
  });

  return <div className="vin-badge-tester">{badges}</div>;
};
