import React, { Suspense, lazy } from 'react';
import { Loader } from '@components/core_ui_elements';

// Bundle these in to main
import * as axios from 'axios';
import * as object_inspect from 'object-inspect';
import * as classnames from 'classnames';

const LazyLoader = params => {
  const components = {
    Billing: lazyWithRetry(() => import('../components/billing/Billing')),
    Branches: lazyWithRetry(() => import('../components/branches/Branches')),
    Form: lazyWithRetry(() => import('../components/form_builder/Form')),
    ManageUsers: lazyWithRetry(() => import('../components/manage_users/ManageUsers')),
    Reports: lazyWithRetry(() => import('../components/reports/Reports')),
    Store: lazyWithRetry(() => import('../components/store/Store')),
    Training: lazyWithRetry(() => import('../components/training/index')),
    Welcome: lazyWithRetry(() => import('../components/welcome/Welcome')),
  };

  const component = React.createElement(components[params.componentName], { ...params.componentParams }, null);

  return <Suspense fallback={<Loader />}>{component}</Suspense>;
};

const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default LazyLoader;
