import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ children, compact, to, hollow, className = '', color = 'green' }) => (
  <Link
    to={to}
    className={`button ${hollow ? color : `n-${color}`}${compact ? ' compact' : ''}${
      hollow ? ' hollow' : ''
    } ${className}`}
  >
    {children}
  </Link>
);

export default ButtonLink;
