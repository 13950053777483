import React from 'react';

const colorClass = ({ hollow, flat, color, text }) => {
  if (text) return 'text';
  // regular buttons have a n- prefix (for legacy reasons)
  let prefix = '';
  if (!hollow && !flat) prefix = 'n-';
  if (flat) prefix = 'flat-';
  return prefix + color;
};

const Button = ({
  onClick,
  disabled,
  children,
  compact,
  type,
  hollow,
  flat,
  text,
  className = '',
  color = 'green',
  ...props
}) => (
  <button
    {...props}
    {...(onClick && { onClick })}
    {...(disabled && { disabled })}
    {...(type && { type })}
    className={`button ${colorClass({ hollow, flat, color, text })}${compact ? ' compact' : ''}${
      hollow ? ' hollow' : ''
    } ${className}`}
  >
    {children}
  </button>
);

export default Button;
