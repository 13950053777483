import React from 'react';
import Popover from 'react-popover';
import { t } from '@components/core_translations';
import { VinBtn } from '@components/vin';

class FilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen } = this.state;
    const { children, isActive } = this.props;
    let el = null;
    if (typeof children === 'function') {
      el = children({ toggle: this.toggle, close: this.close });
    } else {
      el = children;
    }

    return (
      <Popover isOpen={isOpen} place="below" body={el} onOuterAction={() => this.close()}>
        <VinBtn
          active={isOpen || isActive}
          onClick={this.toggle}
          icon
          small
          kind="link"
          className="filter-button"
          variant="default"
        >
          <i className="fa fa-filter fa-fw" />
          <span className="sr-only">{t('Filter Column')}</span>
        </VinBtn>
      </Popover>
    );
  }
}

export const TextFilterButton = ({ onChange, filter, placeholder = t('Search...') }) => (
  <FilterButton isActive={filter != null}>
    {({ close }) => (
      <input
        type="text"
        onKeyPress={e => {
          if (e.key === 'Enter') close();
        }}
        onChange={e => {
          onChange(e.target.value);
        }}
        value={filter ? filter.value : ''}
        placeholder={placeholder}
        autoFocus
      />
    )}
  </FilterButton>
);

export default FilterButton;
