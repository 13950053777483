import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

/* Role ID listing.
  1: Super Master,
  2: Master,
  4: Country Master,
  5: CM Distributor Sales Rep,
  6: Sales Rep,
  7: Global Publisher,
  8: Admin,
  9: Admin Level 2,
  10: Admin Level 3,
  11: Trainee,
  3: Distributor
*/

export const isMaster = user => [1, 2].includes(user.role_id);

export const isAdmin = user => [1, 2, 3, 5, 6, 8, 9].includes(user.role_id);

export const isAdminLevel3 = user => user.role_id == 10;

export const isSalesRep = user => [5, 6].includes(user.role_id);

export const isDistributor = user => user.role_id === 3;

export const isGlobalPublisher = user => user.role_id === 7;

export const abilityFor = (user, distributor, subscription) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (user) {
    can('manage', 'checkout');
    can('read', 'subscription');
    if (subscription && subscription.state === 'active') {
      can('manage', 'Subscription');
    }
  }

  if (user && isMaster(user)) {
    can('manage', 'all');
    can('see', 'SalesNavigation');
  }

  if (user && isSalesRep(user)) {
    can('read', 'SearchContext');
    // sales reps can only view the details of their own accounts
    can('read', 'Account', { salesRepId: user.id.toString() });
    // sales reps can see all invoices within their org.
    can('read', 'Invoice', { distributorId: { $in: ['107', '108'] } });
    // but they can only modify their own
    can('update', 'Invoice', { salesRepId: user.id.toString() });
  }

  if (user && isDistributor(user)) {
    console.log(distributor);
    can('see', 'SalesNavigation');
    can('read', 'Account', { distributorId: distributor.id.toString() });
    can('read', 'Invoice', { distributorId: distributor.id.toString() });
  }

  if (user && (isSalesRep(user) || isDistributor(user))) {
    can('manage', 'salesReport');
    can('manage', 'customer');
    can('see', 'SalesNavigation');
  }

  if (user && isAdmin(user)) {
    // debugger;
    can('manage', 'User');
    can('manage', 'Account');
    can('manage', 'AccountPreference');

    if ((subscription && subscription.state === 'active') || distributor.subscription_reseller) {
      can('build_courses', 'Account');
    } else {
      cannot('build_courses', 'Account');
    }
  }

  if (user && (isAdmin(user) || isAdminLevel3(user))) {
    can('view_reports', 'Account');
    can('see', 'AdminNavigation');
    can('see', 'StoreNavigation');
  }

  if (user && isGlobalPublisher(user)) {
    can('build_courses', 'Account');
    can('see', 'AdminNavigation');
    can('see', 'StoreNavigation');
    can('manage', 'Subscription');
    can('manage', 'product');
  }

  if (!user) {
    can('see', 'StoreNavigation');
  }

  return build({
    detectSubjectType: object => object.__typename,
  });
};
