import React from 'react';

const NotificationImgToIcon = ({ img }) => {
  const icons = {
    '/assets/snippet-file.png': 'fa-file',
    '/assets/snippets-share.png': 'fa-share-from-square',
    '/assets/form-approval-required-icon.png': 'fa-clipboard-question',
    '/assets/form-approval-icon.png': 'fa-clipboard-check',
    '/assets/course_builder-icon.png': 'fa-screwdriver-wrench',
    '/assets/course-library-grey-icon.png': 'fa-inbox-full',
  };
  const iconClass = icons[img] || null;
  return iconClass ? <i className={`fa fa-fw ${iconClass}`} /> : null;
};

export default NotificationImgToIcon;
