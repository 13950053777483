import React from 'react';
import { join, filter } from 'lodash';
import Select, { components } from 'react-select';
import { t } from '@components/core_translations';

const Placeholder = props => {
  return <components.Placeholder {...props}>Choose from the menu below</components.Placeholder>;
};

const Option = props => (
  <components.Option {...props}>
    <i className={`fa ${props.isSelected ? 'fa-check-square-o' : 'fa-square-o'}`} />
    <span>{props.label}</span>
  </components.Option>
);

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {props.getValue().length > 0 && <span>{`${props.getValue().length} ${t('selected')}`}</span>}
    {children}
  </components.ValueContainer>
);

// NOTE: This is intentionally empty!
const IndicatorsContainer = () => '';
// NOTE: This is intentionally empty!
const MultiValueContainer = () => '';

export default class MultiCheckboxDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: null };

    if (props.filterValue) {
      let values = null;
      if (typeof props.filterValue.value === 'string') {
        values = props.filterValue.value.split(',');
      }

      if (typeof props.filterValue.value === 'object') {
        values = props.filterValue.value.map(v => v.value);
      }

      this.state.value = filter(props.options, o => values.includes(o.value));
    }
  }

  render() {
    const { onValueChange, width = 250 } = this.props;
    return (
      <Select
        onChange={value => {
          if (onValueChange) {
            onValueChange(
              join(
                value.map(v => v.value),
                ','
              )
            );
          }
          this.setState({ value });
        }}
        styles={{
          control: base => ({
            ...base,
            width,
          }),
        }}
        components={{
          Option,
          MultiValueContainer,
          ValueContainer,
          IndicatorsContainer,
          Placeholder,
        }}
        value={this.state.value}
        className="select-compact"
        isMulti
        menuIsOpen
        hideSelectedOptions={false}
        isSearchable={false}
        closeMenuOnSelect={false}
        isClearable={false}
        {...this.props}
      />
    );
  }
}
