import moment from 'moment';
import { money } from '@components/core_utils';
import { split } from 'lodash';

export const dateFilter = (filter, row) => {
  const value = moment(row[filter.id]);
  if (filter.value.startDate && !(value >= filter.value.startDate)) {
    return false;
  }

  if (filter.value.endDate && !(value <= filter.value.endDate)) {
    return false;
  }
  return true;
};

export const PAYMENT_STATUS_OPTIONS = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
];

export const PAYMENT_AND_INVOICE_STATUS_OPTIONS = [
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
  { value: 'refunded', label: 'Refunded' },
  { value: 'canceled', label: 'Cancelled' },
  { value: 'in_progress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
];

export const CYCLE_LENGTH_OPTIONS = [
  { value: 'none', label: 'None' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' },
];

export const TYPE_OPTIONS = [
  { value: 'subscription', label: 'Subscription' },
  { value: 'units', label: 'Units' },
];

export const FILTER_TYPE_OPTIONS = [
  { value: '1_month_ago', label: 'Last Month' },
  { value: '2_months_ago', label: '2 Months Ago' },
  { value: '3_months_ago', label: '3 Months Ago' },
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 180, label: '180 days' },
  { value: 365, label: '365 days' },
];

export const moneyFilter = (filter, row) => money(filter.value) === row[filter.id];
export const multiFilter = (filter, row) => split(filter.value, ',').includes(row[filter.id]);
export const getDatesForQuickFilter = ({ value }) => {
  if (typeof value === 'string') {
    switch (value) {
      case '1_month_ago':
        return {
          startDate: moment().subtract(1, 'month').startOf('month'),
          endDate: moment().subtract(1, 'month').endOf('month'),
        };
      case '2_months_ago':
        return {
          startDate: moment().subtract(2, 'month').startOf('month'),
          endDate: moment().subtract(2, 'month').endOf('month'),
        };
      case '3_months_ago':
        return {
          startDate: moment().subtract(3, 'month').startOf('month'),
          endDate: moment().subtract(3, 'month').endOf('month'),
        };
      default:
        return { startDate: null, endDate: null };
    }
  }
  return {
    startDate: moment().subtract(value, 'days').startOf('day'),
    endDate: moment().endOf('day'),
  };
};

export const filterTypeOptions = () => [
  { value: '1_month_ago', label: moment().subtract(1, 'month').format('MMMM, YYYY') },
  { value: '2_months_ago', label: moment().subtract(2, 'month').format('MMMM, YYYY') },
  { value: '3_months_ago', label: moment().subtract(3, 'month').format('MMMM, YYYY') },
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 180, label: '180 days' },
  { value: 365, label: '365 days' },
];
